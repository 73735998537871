import logo from './logo.svg';
import './App.css';
import { retrieveLaunchParams, init } from '@telegram-apps/sdk';
import { useState, useEffect,useRef } from 'react';
import { Bar, Doughnut, Pie , Line} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import HorizontalBarChart from './charts/horizontalBarChart';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
import { useSpring, animated } from 'react-spring';
import trash_icon from './imgs/icon_trash.png';
import default_food_icon from './imgs/def_food_icon.png';
// import Modal_edit from './modal-edit';
// ...
init();
ChartJS.register(ArcElement, Tooltip, Legend);

function Chart({ usr_data }) {
  const chartRef = useRef(null);

  // Рассчитываем общее количество калорий
  const totalCalories = usr_data.reduce((sum, data) => sum + data.calories, 0);

  // Данные для диаграммы
  const data = {
    labels: ['Углеводы', 'Жиры', 'Белки'],
    datasets: [
      {
        data: [
          usr_data.reduce((sum, item) => sum + item.uglevodi, 0),
          usr_data.reduce((sum, item) => sum + item.zhiri, 0),
          usr_data.reduce((sum, item) => sum + item.belki, 0),
        ],
        backgroundColor: ['#A2B9E0', '#C8A2C8', '#98DBC6'],
        borderColor: ['#A2B9E0', '#C8A2C8', '#98DBC6'],
        borderWidth: 1,
      },
    ],
  };

  // Настройки диаграммы
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '80%',
  };

  // Плагин для центра текста
  useEffect(() => {
    const textCenterPlugin = {
      id: 'textCenter',
      beforeDraw(chart) {
        const { width, height } = chart;
        const ctx = chart.ctx;

        ctx.save();

        // Рисуем текст в центре
        ctx.font = '100 12px Helvetica';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#A3ABBE';
        ctx.fillText('Всего', width / 2, height / 2 - 15);

        ctx.fillStyle = '#000';
        ctx.font = '100 18px Helvetica';
        ctx.fillText(`${totalCalories} ккал`, width / 2, height / 2 + 15);

        ctx.restore();
      },
    };

    ChartJS.register(textCenterPlugin); // Регистрируем плагин глобально
    const chart = chartRef.current;

    if (chart) {
      chart.update(); // Принудительно обновляем диаграмму
    }

    // Удаляем плагин при размонтировании, чтобы избежать конфликтов
    return () => {
      ChartJS.unregister(textCenterPlugin);
    };
  }, [usr_data, totalCalories]);

  return (
    <Doughnut
      ref={chartRef}
      data={data}
      options={options}
    />
  );
}

  const initialCards = [
    // { id: 1, title: 'Card 1', content: 'Content of Card 1' },
    // { id: 2, title: 'Card 2', content: 'Content of Card 2' }
  ];
  
function App() {
  
  const [showModal, setShowModal] = useState(false);
  const [showModal_edit, setShowModal_edit] = useState(false);

  const [cards, setCards] = useState([]);
  const [cardId, setCardId] = useState(null);

  const [initialCards, setInitialCards] = useState([]);
  const [currentDate, setCurrentDate] = useState(null); // Текущая выбранная дата
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Состояние для отслеживания загрузки
  const [curIndex, setCurrentIndex] = useState(0); // Состояние для отслеживания загрузки
  const [animationDirection, setAnimationDirection] = useState(0);
  let path_to_default_food_img = '/root/front_food_react/src/def_food_icon.png'

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextDate(), // Свайп влево — следующая дата
    onSwipedRight: () => handlePrevDate(), // Свайп вправо — предыдущая дата
    preventDefaultTouchmoveEvent: true, // Предотвращает прокрутку страницы во время свайпа
    trackMouse: true, // Позволяет использовать свайпы мышью
  });
  const animationProps = useSpring({
    transform: `translateX(${animationDirection * 100}%)`,
    config: { tension: 220, friction: 20 },
    onRest: () => setAnimationDirection(0), // Сбрасываем направление после завершения анимации
  });
  // Функция для добавления новой карточки
  const addNewCard = () => {
    // const newId = cards.length + 1;
    // const newCard = { id: newId,picture : `pic`, dish_name: `Card ${newId}`, calories: `Content of Card ${newId}`, uglevodi : 'ug', belki : ' belki', zhiri : 'zhiri'};
    // setCards([...cards, newCard]);
  };


  useEffect(() => {
    const fetchData = async () => {
      // Получаем initData из retrieveLaunchParams
      const { initData } = retrieveLaunchParams();
      let userid = initData.user.id;//475776712
      try {
        const response_ = await fetch(`https://xn--d1arx6a.xn--p1ai/api/get_user?user_id=${userid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        let data = await response_.json();
            
        data = [...data].sort((a, b) => new Date(b.date_act) - new Date(a.date_act));
        for (let i = 0; i < data.length; i++) {
          // console.log(data[i].date_act.split("T")[0])
        }
        setResponse(data);
        
        if (data.length > 0) {
          setCurrentDate(data[0].date_act.split("T")[0]); // Устанавливаем начальную дату из первого набора данных
        }
        setCurrentIndex(1)
        let new_cards = [];
        for (let i = 0; i < data.length; i++) {
          if(data[i].date_act.split("T")[0] === data[0].date_act.split("T")[0]){
            new_cards.push(data[i]);
          }
        }
        setCards(new_cards);
        setInitialCards(data)
      } catch (error) {
        console.error('Error:', error);
        setResponse('Error submitting data');
      }
       finally {
        setIsLoading(false); // Устанавливаем, что загрузка завершена
      }
    };

    // Вызов асинхронной функции
    fetchData();
  }, []); // [] означает, что эффект сработает только один раз при монтировании компонента
  const removeItem = async (id) => {
      try {
      const response_ = await fetch(`https://xn--d1arx6a.xn--p1ai/api/delete_card?card_id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        
      });

    }
    catch (err) {
    }
    setResponse((prevItems) => prevItems.filter((item) => item.id !== id))

    setCards((prevItems) => prevItems.filter((item) => item.id !== id));
    
    setShowModal(false)

  };
  const count_uglevodi = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += data[i].uglevodi;
    }
    return Math.floor(sum);
  }
 


  const count_zhiri = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += data[i].zhiri;
    }
    return  Math.floor(sum);

  }
  
  
  const count_belki = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += data[i].belki;
    }
    return  Math.floor(sum);

  }

  const getCurrentData = () => {
    // Находим данные, соответствующие текущей дате
   return response.filter((item) => item.date_act.split("T")[0] === currentDate);
  };

  const handlePrevDate = () => {
    // Получаем индексы всех дат
    setAnimationDirection(-1); // Анимация влево

    let dates = new Set(response.map((item) => item.date_act.split("T")[0] ));
    const currentIndex = [...dates].indexOf(currentDate);
    dates =  [...dates];  
    if(dates[currentIndex + 1] == undefined){
    }
    else{
      setCurrentDate(dates[currentIndex + 1]);
      setCurrentIndex(1);
      
        let new_cards = [];
        for (let i = 0; i < response.length; i++) {
          if(response[i].date_act.split("T")[0] === dates[currentIndex + 1]){
            new_cards.push(response[i]);
          }
        }
        setCards(new_cards)
      }
  };

  const handleNextDate = () => {
    setAnimationDirection(1); // Анимация влево

    let dates = new Set(response.map((item) => item.date_act.split("T")[0]));
    const currentIndex = [...dates].indexOf(currentDate); 
    dates =  [...dates];  
    if(dates[currentIndex - 1] == undefined) {
    }
    else {
        setCurrentDate(dates[currentIndex - 1]);
        setCurrentIndex(1);
        let new_cards = [];
          for (let i = 0; i < response.length; i++) {
            if(response[i].date_act.split("T")[0] === dates[currentIndex - 1]){
              new_cards.push(response[i]);
            }
          }
          setCards(new_cards)
        }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const closeModal = () => {
    setShowModal(false)
  };

  const closeModal_edit = () => {
    setShowModal_edit(false)
  };
  const Modal = ({}) => {
    let name;
    console.log(cardId);
    for(let i = 0; i < cards.length; i++){
      if(cardId == cards[i].id){
        name = cards[i].dish_name;
        console.log(cards[i].path_image)

      }
    }
    return(
    <div className="modal-backdrop">
      <div className="modal">
        {/* <button onClick={closeModal} className="close-button"></button> */}
        {/* <img className = 'trash-icon' src = {trash_icon}></img> */}
        <p>Вы уверены?</p>
        <p1>Удалить “{name}” из списка?</p1>
        <div className='buttons-delete'>
          <button onClick = {() => removeItem(cardId)} className = 'delete-button-modal'><p>Удалить</p></button>
          <button onClick={closeModal} className="close-button-modal"><p>Отменить</p></button>
        </div>
      </div>
    </div>
    )
  };
  const showModalbtn = (card_id) =>{
    setShowModal(true); // Открыть модальное окно
    setCardId(card_id);
  }

  const showModalbtn_edit = (card_id) =>{
    setShowModal_edit(true); // Открыть модальное окно
    setCardId(card_id);
  }
  
  const setData = () =>{
    let date = new Date();
    date = date.toISOString();
    date = date.split("T")[0];
    // console.log("DATE : ", date);
    // console.log(currentDate);
    if(date == currentDate){return 'Сегодня'}
    else return `${currentDate.split('-')[2]}-${currentDate.split('-')[1]}-${currentDate.split('-')[0]}`
  }
  return (
    
    <div className="App">
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>

      <header className="App-header" {...swipeHandlers}>
          {/* <button className = 'modal-btn' onClick = {() => showModalbtn()}></button> */}
              {/* Отображаем все существующие карточки */}
              {showModal && (
              <Modal onClose={closeModal}>
              <div className="head">
        </div>
        </Modal>
          )}
              {/* {showModal_edit && (
              <Modal_edit onClose={closeModal_edit}>
              <div className="head">
        </div>
        </Modal_edit>
          )} */}
      {/* Кнопка для добавления новой карточки */}
         <div className="navigation" // Применяем обработчики свайпа к div
         >
         <button className = 'arrow-back' onClick={handlePrevDate} ></button>
        <p>        
          {setData()}  
        {/* {currentDate.split('-')[2]}-{currentDate.split('-')[1]}-{currentDate.split('-')[0]} */}
        </p>
        <button className = 'arrow-forward' onClick={handleNextDate}></button>
        </div>
        <div class = "resize_chart">
        <Chart usr_data = {cards}></Chart>
        </div>
        <div className= 'legend'>
          <p className ='uglevodi'>Углеводы ({count_uglevodi(cards)})</p>
          <p className ='belki'>Белки ({count_belki(cards)})</p>
          <p className ='zhiri'>Жиры ({count_zhiri(cards)})</p>
        </div>
        <div className = 'main-table'>
          <h>Блюда</h>
          {/* <button onClick={addNewCard}>Add New Card</button> */}

          <div className = 'table'>
          <TransitionGroup className="list">
          {cards.map((card) => (
     <CSSTransition
     key={card.id}
     timeout={500}
     classNames="fade-slide"
   >

<div className = 'raw-food-data'>
{/* https://xn--d1arx6a.xn--p1ai/public/${card.path_image} */} 
<div className = 'raw-data'>
  <div className = 'img-product' style={{backgroundImage:`url(https://xn--d1arx6a.xn--p1ai/api/public/${card.path_image})`}}></div>
  <div className = 'name-calories-product'>
    <h1>{card.dish_name}</h1>
    <p>{card.calories} ккал</p>
  </div>
  <div className = 'edit-delete-product'>
  <button className = 'btn-edit'></button>
  <button className = 'btn-delete'  onClick = {() => showModalbtn(card.id)}></button>
  </div>
</div>
<div className = 'legend-data'>

<div className = 'bar-chart'><HorizontalBarChart uglevodi = {card.uglevodi} belki = {card.belki} zhiri = {card.zhiri}></HorizontalBarChart></div>
<div className = 'legend-bar-chart'>
  <p>{card.uglevodi}</p>
  <p className='not-standing-p' style = {{marginLeft: `${(((card.uglevodi/(card.uglevodi + card.belki + card.zhiri) * 100)  + ((card.belki/((card.uglevodi + card.belki + card.zhiri)) ) * 100 )/2) - 50) * 2 }%`}}>{card.belki}</p>
  <p>{card.zhiri}</p>
</div>
</div>
</div>
</CSSTransition>
))}
</TransitionGroup>

         </div>
        </div>
        {/* <div className="navigation">
          <button>
          <button onClick={handlePrevDate} disabled={response.findIndex((item) => item.date_act.split("T")[0] === currentDate) === 0}> 
            ← Previous Date
          </button>
          <button>
          <button onClick={handleNextDate} disabled={response.findIndex((item) => item.date_act.split("T")[0]  === currentDate) === response.length - 1}>
            Next Date →
          </button>
        </div> */}
      </header>
    </div>
  );
}


export default App;
