import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
// Регистрация компонентов Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

const HorizontalBarChart = ({uglevodi, belki, zhiri}) => {

  let sum  =uglevodi + belki + zhiri;
  // console.log("UGLEVODI : ",  uglevodi/sum)
  // console.log("BELKi : ",  belki/sum)
  // console.log("ZHiri : ",  zhiri/sum)
  // Данные для графика
  const data = {
    labels: [""], // Пустая метка, так как у нас только один ряд данных
    datasets: [
      {
        label: "Фиолетовая часть",
        data: [Math.round((uglevodi/sum) * 100)],
        backgroundColor: "#A2B9E0", // Цвет фиолетового блока
      },
      {
        label: "Зеленая часть",
        data: [Math.round((belki/sum) * 100)],
        backgroundColor: "#98DBC6", // Цвет зеленого блока
      },
      {
        label: "Розовая часть",
        data: [Math.round((zhiri/sum) * 100)],
        backgroundColor: "#C8A2C8", // Цвет розового блока
      },
    ],
  };

  // Опции для графика
// Опции для графика
const options = {
  responsive: true,

  maintainAspectRatio: false, // Отключение фиксированного соотношения сторон
  layout: {
    padding: 0, // Убираем отступы
  },
  indexAxis: "y", // Горизонтальная ориентация
  plugins: {
    tooltip: {
      enabled: false, // Включаем всплывающие подсказки
    },
    legend: {
      display: false, // Убираем легенду
    },
    customLabels: {
      id: 'customLabels',
      afterDatasetsDraw(chart) {
        const { ctx, scales, data } = chart;
        const datasets = data.datasets;

        ctx.save();

        datasets.forEach((dataset, datasetIndex) => {
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.data.forEach((bar, index) => {
            const value = dataset.data[index];
            const x = bar.x; // Центр каждого бара
            const y = bar.y + 15; // Положение под графиком

            ctx.fillStyle = '#A3ABBE';
            ctx.textAlign = 'center';
            ctx.font = '12px Helvetica';
            ctx.fillText(value, x, y); // Рисуем текст
          });
        });

        ctx.restore();
      },
    },
  },

  scales: {
    x: {
      max: 100,
      beginAtZero: true,
      display: false,
      stacked: true, // Включаем стекированный режим по оси X
      ticks: {
        display: false, // Убираем деления оси X
      },
      grid: {
        display: false, // Убираем линии сетки
      },
    },
    y: {
      beginAtZero: true,
      display: false,
      stacked: true, // Включаем стекированный режим по оси Y
      ticks: {
        display: false, // Убираем деления оси Y
      },
      grid: {
        display: false, // Убираем линии сетки
      },
    },
  },
};


  return (
<div className style={{position : 'inherit', width: "100%", height: "6px", padding: "0", margin: "0", boxSizing: 'content-box' }}>
<Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
